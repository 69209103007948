// Imports
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import icons
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";

// Import Data
import { PartnersData } from '../../data/partnes';

// Imports CSS
import './OurPartners.css'

const OurPartners = () => {
    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-arrow custom-next-arrow" style={{ right: '-48px' }} onClick={onClick} role="button" tabIndex={0}>
            <BsArrowRightCircle size={33} />
        </div>
    );

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-arrow custom-prev-arrow" style={{ left: '-48px' }} onClick={onClick} role="button" tabIndex={0}>
            <BsArrowLeftCircle size={33} />
        </div>
    );

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section className="content-ourpartners">
            <div className="ourpartners-title">
                <div className="texts">
                    <h1 style={{ fontSize: '40px' }}>Nossos <span style={{ color: '#E23233', fontWeight: '600' }}>parceiros</span></h1>
                </div>
            </div>
            <div className="ourpartners-partners">
                <Slider {...settings}>
                    {PartnersData.map((partner, index) => (
                        <div className="ourpartners-partner" key={index}>
                            <div className="ourpartners-image" style={{ background: `url(${partner.logo})` }}></div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    )
}

export default OurPartners;