export const PartnersData = [
    {
        logo: 'https://joelveiculospv.com.br/db/images/banks/bank-bradesco.png',
    },
    {
        logo: 'https://joelveiculospv.com.br/db/images/banks/bank-safra.png',
    },
    {
        logo: 'https://joelveiculospv.com.br/db/images/banks/bank-santander.png',
    },
    {
        logo: 'https://joelveiculospv.com.br/db/images/banks/bank-brasil.png',
    },
    {
        logo: 'https://joelveiculospv.com.br/db/images/banks/bank-pan.png',
    },
    {
        logo: 'https://joelveiculospv.com.br/db/images/banks/bank-bv.png',
    },
    {
        logo: 'https://joelveiculospv.com.br/db/images/banks/bank-itau.png',
    },
    {
        logo: 'https://joelveiculospv.com.br/db/images/banks/bank-c6.png',
    }
]