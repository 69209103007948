// Imports
import React from 'react';

// Import Data
import { QualitiesData } from '../../data/qualities';

// Imports CSS
import './OurQualities.css'

const OurQualities = () => {
    return (
        <section className="content-ourqualities">
            <div className="ourqualities-data">
                {QualitiesData.map((quality, index) => (
                    <div className="ourqualities-item" key={index}>
                        <div className="ourqualities-icon" style={{ background: `url(${quality.image})` }}></div>
                        <h1 className="ourqualities-title">{`${quality.title}`}</h1>
                        <h2 className="ourqualities-description">{`${quality.description}`}</h2>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default OurQualities