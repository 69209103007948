// Imports
import React from "react";
import Carousel from "../components/Carousel/Carousel.jsx";

// Import data
import { GlobalData } from '../data/global.js'

// Import CSS
import '../styles/About.css'

const About = () => {
    return (
        <div>
            <Carousel />

            <section className="content-about" style={{ padding: '0, 2em, 0, 0' }}>
            <div className="texts" style={{ width: '80%' }}>
                    <h1 style={{ fontSize: '40px', fontWeight: '400' }}>Nossa <span style={{ color: '#E23233', fontWeight: '600' }}>história</span></h1>
                </div>
                <div className="about-ourstory">
                    <div className="about-image ourstory" style={{ background: `url(${GlobalData.about.ourstory.image})` }}></div>
                    <h4 className="about-text">{GlobalData.about.ourstory.text}</h4>
                </div>

                <div className="texts" style={{ width: '80%', display: 'flex', justifyContent: 'flex-end' }}>
                    <h1 style={{ fontSize: '40px', fontWeight: '400' }}>Nossa <span style={{ color: '#E23233', fontWeight: '600' }}>equipe</span></h1>
                </div>
                <div className="about-ourteam about-reverse">
                    <div className="about-image ourteam" style={{ background: `url(${GlobalData.about.ourteam.image})` }}></div>
                    <h4 className="about-text about-reverse">{GlobalData.about.ourstory.text}</h4>
                </div>
            </section>
        </div>
    )
}

export default About;