// Imports
import React  from "react";

// Import Data
import { GlobalData } from "../../data/global";

const Footer = () => {
    const styleContent = {
        color: '#FFF',
        width: '100%',
        padding: '10px',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: '#E23233'
    }

    return (
        <section className="content-footer" style={styleContent}>
            <h1 style={{ fontSize: '14px', fontWeight: '400' }}>{GlobalData.footer.text}</h1>
        </section>
    )
}

export default Footer;