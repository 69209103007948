// Imports
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import icons
import { FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa';

// Import CSS
import './Carousel.css'

const Carousel = () => {
    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-arrow main-custom-next-arrow" style={{ right: '-48px' }} onClick={onClick} role="button" tabIndex={0}>
            <FaAngleDoubleRight size={33} />
        </div>
    );

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-arrow main-custom-prev-arrow" style={{ left: '-48px' }} onClick={onClick} role="button" tabIndex={0}>
            <FaAngleDoubleLeft size={33} />
        </div>
    );

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        focusOnSelect: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://joelveiculospv.com.br/db/api/get-slides.php');
                const slidesData = response.data;

                const getSlidesApi = slidesData.map(slide => {
                    const keys = Object.keys(slide).filter(key => key.startsWith("slide_"));
                    return keys.map(key => slide[key]);
                }).flat();

                setData(getSlidesApi);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div></div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="main-caraousel-slick">
            <Slider {...settings}>
                {data && data.map((slide, index) => {
                    return (
                        <div className="main-carousel-partner" key={index}>
                            <div className="main-carousel-image" style={{ background: `url(https://joelveiculospv.com.br/admin/images/slides/${slide})` }}></div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default Carousel;