// Imports
import { LiaFacebook, LiaPhoneSolid } from "react-icons/lia";
import { FaInstagram } from "react-icons/fa";
import { MdOutlinePlace } from "react-icons/md";

// Import data
import { GlobalData } from "../../data/global";

// Import CSS
import './Header.css';

const Header = () => {
    const handleRedirectTo = (url) => {
        window.open(url, "_blank");
    };

    return (
        <header className="header">
            <div className="content">
                <div className="place">
                    <button className="place" onClick={() => handleRedirectTo(`${GlobalData.header.address.place}`)}>
                        <MdOutlinePlace style={{ fontSize: '1.5em' }} />
                        <h4 className="streetAndCity">{GlobalData.header.address.street}</h4>
                    </button>
                </div>

                <div className="socials">
                    <button className="icone-social phone" onClick={() => handleRedirectTo(`${GlobalData.header.socials.phone}`)} style={{ fontSize: '1.3em' }}> <LiaPhoneSolid /> </button>
                    <button className="icone-social instagram" onClick={() => handleRedirectTo(`${GlobalData.header.socials.instagram}`)} style={{ fontSize: '1.2em' }}> <FaInstagram /> </button>
                    <button className="icone-social facebook" onClick={() => handleRedirectTo(`${GlobalData.header.socials.facebook}`)} style={{ fontSize: '1.5em' }}> <LiaFacebook /> </button>
                </div>
            </div>
        </header>
    )
}

export default Header;