// Imports
import axios from 'axios';
import React, { useState, useEffect } from "react";
import SearchCar from "../components/SearchCar/SearchCar";
import MainCars from "../components/MainCars/MainCars";
import OurQualities from "../components/OurQualities/OurQualities";
import OurPartners from "../components/OurPartners/OurPartners";
import Carousel from "../components/Carousel/Carousel.jsx";

// Import data
import { SliderData } from '../data/carousel.js'

const Home = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://joelveiculospv.com.br/db/api/get-cars.php');
                setData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {loading ? (
                <div></div>
            ) : error ? (
                <div>Error: {error.message}</div>
            ) : (
                <>
                    <Carousel />
                    <SearchCar data={data} />
                    <MainCars data={data} />
                    <OurQualities />
                    <OurPartners />
                </>
            )}
        </div>
    )
}

export default Home;