// Imports
import React  from "react";
import ReactDOM from "react-dom/client"
import reportWebVitals from './reportWebVitals';
import App from "./App"

// Imports CSS
import './App.css'

// Config router
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

// Import routes
import Home from "./routes/Home";
import About from "./routes/About";
import Cars from "./routes/Cars";
import Contact from "./routes/Contact";
import ProductPage from "./routes/ProductPage";
import CarsSearched from "./routes/carsSearched";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Navigate to="/" />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "quem-somos",
                element: <About />
            },
            {
                path: "veiculos",
                element: <Cars />
            },
            {
                path: "veiculo",
                element: <ProductPage />
            },
            {
                path: "/buscar-veiculos",
                element: <CarsSearched />
            },
            {
                path: "veiculo/:id/",
                element: <ProductPage />
              },
            {
                path: "contato",
                element: <Contact />
            }
        ]
    },
])

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
)

reportWebVitals();