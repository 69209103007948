// Imports
import React from "react";
import Carousel from "../components/Carousel/Carousel.jsx";
import { FaInstagram, FaFacebook, FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";

// Import data
import { GlobalData } from '../data/global.js'

// Import CSS
import '../styles/Contact.css'

const Contact = () => {
    const handleRedirectTo = (url) => {
        window.open(url, "_blank");
    };

    return (
        <div>
            <Carousel />

            <section className="content-contact" style={{ padding: '0, 2em, 0, 0' }}>
                <div className="texts" style={{ width: '80%' }}>
                    <h1 style={{ fontSize: '40px', fontWeight: '400' }}>Fale <span style={{ color: '#E23233', fontWeight: '600' }}>conosco!</span></h1>
                    <h4 style={{ fontSize: '15px', fontWeight: '400' }}>Escolha um dos métodos de contato abaixo, que iremos lhe propôrcionar a sua melhor experiência na compra do seu veículo.</h4>
                </div>
                <section className="contact-methods">
                    <button className="whatsapp social" onClick={() => handleRedirectTo(`${GlobalData.header.socials.whatsapp}`)}>
                        <FaWhatsapp className="icon" style={{ fontSize: '32px' }} />
                        <h4>Entrar em contato via Whatsapp</h4>
                    </button>

                    <button className="phone social" onClick={() => handleRedirectTo(`${GlobalData.header.socials.phone}`)}>
                        <FaPhoneAlt className="icon" style={{ fontSize: '26px' }} />
                        <h4>Entrar em contato via Telefone</h4>
                    </button>

                    <button className="instagram social" onClick={() => handleRedirectTo(`${GlobalData.header.socials.instagram}`)}>
                        <FaInstagram className="icon" style={{ fontSize: '32px' }} />
                        <h4>Entrar em contato via Instagram</h4>
                    </button>

                    <button className="facebook social" onClick={() => handleRedirectTo(`${GlobalData.header.socials.facebook}`)}>
                        <FaFacebook className="icon" style={{ fontSize: '32px' }} />
                        <h4>Entrar em contato via Facebook</h4>
                    </button>

                    <button className="maps social" onClick={() => handleRedirectTo(`${GlobalData.header.socials.place}`)}>
                        <FiMapPin className="icon" style={{ fontSize: '30px' }} />
                        <h4>Conhecer a Joel Veículos pessoalmente</h4>
                    </button>
                </section>
            </section>
        </div>
    )
}

export default Contact;