export const QualitiesData = [
    {
        image: 'https://joelveiculospv.com.br/db/images/icons/quality-one.png',
        title: 'Credibilidade',
        description: 'Contamos com mais de 25 anos de experiência no mercado de compra e venda de veículos.'
    },
    {
        image: 'https://joelveiculospv.com.br/db/images/icons/quality-two.png',
        title: 'Veículos selecionados',
        description: 'Nossos veículos passam por um rigoroso controle de qualidade, garantindo o sucesso no seu novo carro.'
    },
    {
        image: 'https://joelveiculospv.com.br/db/images/icons/quality-three.png',
        title: 'Sua melhor experiência',
        description: 'Estamos preparados para lhe proporcionar a melhor experiência na compra do seu novo veículo.'
    },
    {
        image: 'https://joelveiculospv.com.br/db/images/icons/quality-four.png',
        title: 'Preços incríveis',
        description: 'Garantimos qualidade e preços incríveis em nossos veículos.'
    }
]