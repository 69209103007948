// Imports
import React, { useState, useEffect } from 'react';
import PageProductCarousel from '../components/PageProductCarousel/PageProductCarousel.jsx';

const ProductPage = () => {
    const [carData, setCarData] = useState([]);
    const [carId] = useState(0);
    useEffect(() => {
        function readCars(id) {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `https://joelveiculospv.com.br/db/api/get-car.php?id=${id}`, true);
            xhr.responseType = 'text';
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const res = JSON.parse(xhr.response)
                    setCarData(res);
                } else {
                    console.error('Erro na conexão com banco de dados:', xhr.statusText);
                }
            };

            xhr.send();
        }

        const getCarId = RegExp(/veiculo\/(\d+)/).exec(window.location.href)
        readCars(getCarId[1]);
    }, [carId]);

    return (
        <div>
            {carData.length > 0 && <PageProductCarousel car={carData} />}
        </div>
    )
}

export default ProductPage;