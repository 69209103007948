// Imports
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

// Import data
import { GlobalData } from "../../data/global";

// Import CSS
import './Menu.css';

const Menu = () => {
    const data = {
        menu: {
            home: '/',
            equipe: '/quem-somos',
            veiculos: '/veiculos',
            contato: '/contato'
        },
    }

    const handleRedirectTo = (url) => {
        window.open(url, "_blank");
    };

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const handleMenuClick = () => {
        if (window.innerWidth < 830) {
            document.getElementById('menu-global').classList.remove('active');
        }

        setClick(!click);
    };

    return (
        <header className="menu">
            <div className="content">
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <img src="https://joelveiculospv.com.br/db/images/logo.png" alt="logo" id="logo" />
                </Link>

                <div className="menu-icons" onClick={handleClick}>
                    {click ? <FaTimes /> : <FaBars />}
                </div>

                <div className={`sub-content ${click ? 'active' : ''}`} id="menu-global">
                    <nav className="options">
                        <ul>
                            <li> <Link to={data.menu.home} onClick={handleMenuClick}>Home</Link> </li>
                            <li> <Link to={data.menu.equipe} onClick={handleMenuClick}>Quem somos</Link> </li>
                            <li> <Link to={data.menu.veiculos} onClick={handleMenuClick}>Veículos</Link> </li>
                            <li> <Link to={data.menu.contato} onClick={handleMenuClick}>Contato</Link> </li>
                        </ul>
                    </nav>


                    <button className="fastContact" id="btnFastContact" onClick={() => handleRedirectTo(`${GlobalData.header.socials.whatsapp}`)}>Contato rápido via Whatsapp</button>
                </div>
            </div>
        </header>
    )
}

export default Menu;