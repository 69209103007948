// Imports
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import icons
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";

// Imports CSS
import './MainCars.css'

// Shuffle array
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

const MainCars = (data) => {
    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-arrow custom-next-arrow" style={{ right: '-48px' }} onClick={onClick} role="button" tabIndex={0}>
            <BsArrowRightCircle size={33} />
        </div>
    );

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-arrow custom-prev-arrow" style={{ left: '-48px' }} onClick={onClick} role="button" tabIndex={0}>
            <BsArrowLeftCircle size={33} />
        </div>
    );

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const navigate = useNavigate();

    const handleRedirectToProductPage = (id) => {
        const url = `/veiculo/${id}/`;
        navigate(url, { target: '_blank' });
    };

    const shuffledData = [...data.data];
    shuffleArray(shuffledData);
    const limitedCars = shuffledData.slice(0, 8);

    return (
        <section className="content-maincars">
            <div className="maincars-title">
                <div className="maincars-texts">
                    <h1 style={{ fontSize: '40px' }}>Novidades para <span style={{ color: '#E23233', fontWeight: '600' }}> você</span></h1>
                </div>

                <Link to='/veiculos' className="see-all-cars-link" style={{ textDecoration: 'none' }}>
                    <button className="see-all-cars">
                        Ver todos os veículos
                    </button>
                </Link>
            </div>
            <div className="maincars-cars">
                <Slider {...settings}>
                    {limitedCars.length > 0 && limitedCars.map((car, index) => (
                        <div className="maincars-car" key={index}>
                            <div className="maincars-image" style={{ background: `url(https://joelveiculospv.com.br/admin/images/cars/${car.foto_principal})` }}></div>
                            <div className="maincars-data">
                                <h1 className="maincars-brand">{`${car.modelo}`}</h1>
                                <h2 className="maincars-model">{`${car.versao}`}</h2>
                                <h1 className="maincars-value">{`${car.preco}`}</h1>
                            </div>

                            <div className="maincars-others">
                                <h4 className="maincars-km" style={{ fontWeight: '500' }}>{`${car.quilometragem}`} KM</h4>
                                <h4 className="maincars-years" style={{ fontWeight: '500' }}>{`${car.ano_fabricacao}`}/{`${car.ano_modelo}`}</h4>
                            </div>

                            <button className="see-more-details" onClick={() => handleRedirectToProductPage(car.id)}>Ver mais detalhes</button>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}

export default MainCars;