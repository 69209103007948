// Imports
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Import CSS
import './SearchCar.css';

const SearchCar = (data) => {
    const navigate = useNavigate()
    const [brands, setBrands] = useState([]);
    const [models, setModel] = useState([]);
    const [years, setYears] = useState([]);
    const [formattedYears, setFormattedYears] = useState([]);
    const [modelYears, setYearsModel] = useState([]);
    const [formattedModelYears, setFormattedYearsModel] = useState([]);
    const [selectedBrand, setSelectedMarca] = useState('');
    const [selectedModel, setSelectedModelo] = useState('');
    const [selectedYear, setSelectedAnoFabricacao] = useState('');
    const [selectedModelYear, setSelectedAnoModelo] = useState('');

    useEffect(() => {
        function getBrandsInStock(data) {
            const modelSet = new Set();
            data.forEach(item => {
                modelSet.add(item.modelo);
            });

            return Array.from(modelSet);
        }

        setBrands(getBrandsInStock(data.data));
    }, [data]);

    const handleBrandChange = (event) => {
        const brandCode = event.target.value;
        setSelectedMarca(brandCode);
        setSelectedModelo('');
        setSelectedAnoFabricacao('');
        setSelectedAnoModelo('');

        function getModelsInStock(brandName) {
            const models = new Set();
            data.data.forEach(item => {
                if (item.modelo === brandName) {
                    models.add(item.versao);
                }
            });
            return Array.from(models);
        }

        setModel(getModelsInStock(brandCode));
    };

    const handleModelChange = (event) => {
        const modelCode = event.target.value;
        setSelectedModelo(modelCode);
        setSelectedAnoFabricacao('');
        setSelectedAnoModelo('');

        function getYearsInStock(brand, model) {
            return data.data.filter(item => item.modelo === brand && item.versao === model);
        }

        setYears(getYearsInStock(selectedBrand, modelCode))
        setYearsModel(getYearsInStock(selectedBrand, modelCode))
    };

    useEffect(() => {
        function formatYearsInStock(data) {
            let cars = [];

            data.forEach(carro => {
                if (!cars.includes(carro.ano_fabricacao)) {
                    cars.push(carro.ano_fabricacao);
                }
            });

            const newCars = cars.slice();
            return newCars.toSorted()
        }

        setFormattedYears(formatYearsInStock(years));
    }, [years]);

    const handleYearChange = (event) => {
        setSelectedAnoFabricacao(event.target.value);
    };

    useEffect(() => {
        function formatYearsModelInStock(data) {
            let cars = [];

            modelYears.forEach(carro => {
                if (!cars.includes(carro.ano_modelo)) {
                    cars.push(carro.ano_modelo);
                }
            });

            const allModels = cars.slice();
            const allModelsInOrder = allModels.toSorted((a, b) => parseInt(a) - parseInt(b));

            const filteredYears = allModelsInOrder.filter(function (anos) {
                return parseInt(anos) >= parseInt(data);
            });

            return filteredYears;
        }

        setFormattedYearsModel(formatYearsModelInStock(selectedYear));
    }, [modelYears, selectedYear]);

    const handleModelYearChange = (event) => {
        setSelectedAnoModelo(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const filteredVehicles = data.data.filter((vehicle) => {
            if (selectedBrand && vehicle.modelo !== selectedBrand) {
                return false;
            }

            if (selectedModel && vehicle.versao !== selectedModel) {
                return false;
            }

            return !(selectedYear &&
                (parseInt(vehicle.ano_fabricacao) < parseInt(selectedYear) ||
                    parseInt(vehicle.ano_modelo) > parseInt(selectedModelYear)));
        });

        navigate('/buscar-veiculos', { state: { filteredVehicles } });
        window.scrollTo(0, 0);
    };

    return (
        <section className="content-searchcar">
            <div className="texts">
                <h1 style={{ fontSize: '40px' }}>Busque pelo <span style={{ color: '#E23233', fontWeight: '600' }}> seu novo veículo</span></h1>
                <h2 style={{ fontSize: '24px', marginTop: '8px' }}>ele está <span style={{ color: '#E23233', fontWeight: '600' }}>na Joel Veículos!</span></h2>
                <h3 style={{ fontSize: '15px' }}>Utilize nossos filtros inteligentes e busque o veículo ideal para você, em nosso estoque.</h3>
            </div>

            <form className="searchcar" onSubmit={handleSubmit}>
                <span className="form-selects">
                    <label>
                        Marca
                        <div className="select">
                            <select className="custom-select brand" value={selectedBrand} style={{ width: '245px' }} onChange={handleBrandChange}>
                                <option value="" disabled hidden>Selecione..</option>
                                {brands.length > 0 && brands.map((brand) => (
                                    <option key={brand} value={brand} style={{ fontSize: '14px' }}>
                                        {brand.toUpperCase()}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </label>

                    <label>
                        Modelo
                        <div className="select">
                            <select className="custom-select model" value={selectedModel} style={{ width: '330px', paddingRight: '2.8em' }} onChange={handleModelChange} disabled={!selectedBrand}>
                                <option value="" disabled hidden>Selecione..</option>
                                {models.map((model) => (
                                    <option key={model} value={model}>
                                        {model.toUpperCase()}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </label>

                    <label>
                        Ano de Fabricação
                        <div className="select">
                            <select className="custom-select year" value={selectedYear} style={{ width: '190px' }} onChange={handleYearChange} disabled={!selectedBrand || !selectedModel}>
                                <option value="" disabled hidden>Selecione..</option>
                                {formattedYears.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </label>

                    <label>
                        Ano Modelo
                        <div className="select">
                            <select className="custom-select year-model" value={selectedModelYear} style={{ width: '190px' }} onChange={handleModelYearChange} disabled={!selectedBrand || !selectedModel || !selectedYear}>
                                <option value="" disabled hidden>Selecione..</option>
                                {formattedModelYears.map((modelYear) => (
                                    <option key={modelYear} value={modelYear}>
                                        {modelYear}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </label>
                </span>

                <button type="submit" className="send-searchcar" disabled={!selectedBrand}>
                    Buscar veículos
                </button>

                <Link to='/veiculos' style={{ textDecoration: 'none', width: '100%' }}>
                    <button className="see-all-cars">Ver todos os veículos</button>
                </Link>
            </form>
        </section>
    )
}

export default SearchCar;