// Imports
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import icons
import { FaAngleDoubleRight, FaAngleDoubleLeft, FaWhatsapp } from 'react-icons/fa';

// Imports CSS
import './PageProductCarousel.css'

const PageProductCarousel = ({ car }) => {
    const handleRedirectTo = (url) => {
        window.open(url, "_blank");
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-arrow main-custom-next-arrow" style={{ right: '-48px' }} onClick={onClick} role="button" tabIndex={0}>
            <FaAngleDoubleRight size={33} />
        </div>
    );

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-arrow main-custom-prev-arrow" style={{ left: '-48px' }} onClick={onClick} role="button" tabIndex={0}>
            <FaAngleDoubleLeft size={33} />
        </div>
    );

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        focusOnSelect: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // Data mapping
    const allPhotos = car[0].foto_principal + ',' + car[0].fotos_alternativas.split(',')
    const allSpecificCharacteristics = car[0].caracteristicas_especificas

    return (
        <div>
            <section className="content-main-carousel car-page">
                <div className="main-carousel-images">
                    <Slider {...settings}>
                        {allPhotos.split(',').map((photo, index) => (
                            <div className="main-carousel-partner" key={index}>
                                <div className="main-base-carousel-image" style={{ background: `url(https://joelveiculospv.com.br/admin/images/cars/${photo})` }}></div>
                                <div className="main-carousel-image" style={{ background: `url(https://joelveiculospv.com.br/admin/images/cars/${photo})` }}></div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>

            <section className="content-pageproduct">
                <section className="pageproduct-main">
                    <div>
                        <h1 className="product-brand" style={{ textTransform: 'uppercase' }}>{car[0].modelo}</h1>
                        <h4 className="product-model" style={{ textTransform: 'uppercase' }}>{car[0].versao}</h4>
                        <h2 className="product-value" style={{ textTransform: 'uppercase' }}>{car[0].preco}</h2>
                    </div>
                    <button
                        className="contact-seller"
                        onClick={() => handleRedirectTo(`https://wa.me//5518996031121?text=Gostei%20muito%20deste%20veículo:%20${window.location.href},%20gostaria%20de%20maiores%20informações%20sobre%20ele.`)}>
                        <FaWhatsapp size={28} style={{ marginRight: '12px' }} />Falar com o Vendedor
                    </button>
                </section>

                <section className="pageproduct-other">
                    <div className="base-label">
                        <div className="label-title">principais características</div>
                    </div>
                    <div className="base-info">
                        <div className="base-details main">
                            <div>
                                <h1>Ano</h1>
                                <h4>{car[0].ano_fabricacao}/{car[0].ano_modelo}</h4>
                            </div>
                            <div>
                                <h1>Final da Placa</h1>
                                <h4>{car[0].final_placa}</h4>
                            </div>
                            <div>
                                <h1>KM</h1>
                                <h4>{car[0].quilometragem}</h4>
                            </div>
                            <div>
                                <h1>Cor</h1>
                                <h4>{car[0].cor}</h4>
                            </div>
                            <div>
                                <h1>Tipo de Combustível</h1>
                                <h4>{car[0].combustivel}</h4>
                            </div>
                            <div className="cambio">
                                <h1>Tipo de Câmbio</h1>
                                <h4>{car[0].cambio}</h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pageproduct-other">
                    <div className="base-label">
                        <div className="label-title">demais características</div>
                    </div>
                    <div className="base-info">
                        <div className="base-details" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {allSpecificCharacteristics.split(',').map((characteristics) => (
                                <div className="base-option-others" style={{ flex: '0 0 33%', marginBottom: '8px' }} key={characteristics}>
                                    <h3>{characteristics}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="pageproduct-other" style={{ marginBottom: '30px' }}>
                    <div className="base-label">
                        <div className="label-title">descrição</div>
                    </div>
                    <div className="base-info">
                        <div className="base-details">
                            <h6 style={{ fontSize: '18px', fontWeight: '500' }}>{car[0].caracteristicas}</h6>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}

export default PageProductCarousel;