// Imports
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Carousel from "../components/Carousel/Carousel.jsx";

// Import CSS
import '../styles/Cars.css'

const Cars = () => {
  const [cars, setCarsData] = useState([]);
  useEffect(() => {
    function readCars(arquivoPHP) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', arquivoPHP, true);
      xhr.responseType = 'text';
      xhr.onload = function () {
        if (xhr.status === 200) {
          const Cars = JSON.parse(xhr.response);
          setCarsData(Cars);
        } else {
          console.error('Erro na conexão com banco de dados:', xhr.statusText);
        }
      };

      xhr.send();
    }

    readCars('https://joelveiculospv.com.br/db/api/get-cars.php');
  }, []);

  const navigate = useNavigate();
  const handleRedirectToProductPage = (id) => {
    const url = `/veiculo/${id}/`;
    navigate(url, { target: '_blank' });
  };

  const [selectedFilter, setSelectedFilter] = useState('');
  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const filteredCars = cars.toSorted();
  if (selectedFilter) {
    switch (selectedFilter) {
      case 'lowest-price':
        filteredCars.sort((a, b) => parseFloat(parseFloat(a.preco.replace(/R\$ |\./g, '').replace(/,\d+/, '')) - parseFloat(b.preco.replace(/R\$ |\./g, '').replace(/,\d+/, ''))));
        break;
      case 'highest-price':
        filteredCars.sort((a, b) => parseFloat(b.preco.replace(/R\$ |\./g, '').replace(/,\d+/, '')) - parseFloat(parseFloat(a.preco.replace(/R\$ |\./g, '').replace(/,\d+/, ''))));
        break;
      case 'lowest-km':
        filteredCars.sort((a, b) => parseFloat(parseFloat(a.quilometragem.replace(/[.,]/g, '')) - parseFloat(b.quilometragem.replace(/[.,]/g, ''))));
        break;
      case 'highest-km':
        filteredCars.sort((a, b) => parseFloat(b.quilometragem.replace(/[.,]/g, '')) - parseFloat(parseFloat(a.quilometragem.replace(/[.,]/g, ''))));
        break;
      case 'lowest-year':
        filteredCars.sort((a, b) => a.ano_fabricacao - b.ano_fabricacao);
        break;
      case 'highest-year':
        filteredCars.sort((a, b) => b.ano_fabricacao - a.ano_fabricacao);
        break;

      default:
        break;
    }
  }

  const [buttonWidth, setButtonWidth] = useState('');
  const handleResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 664) {
      const ourCarsCarElement = document.querySelector('.ourcars-car');
      if (ourCarsCarElement) {
        const ourCarsCarWidth = ourCarsCarElement.getBoundingClientRect().width;
        const newButtonWidth = ourCarsCarWidth - 31 + 'px';
        setButtonWidth(newButtonWidth);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  return (
    <div>
      <Carousel />

      <section className="content-cars" style={{ padding: '0, 2em, 0, 0' }}>
        <div className="ourcars-interactions">
          <h1 style={{ fontSize: '40px', fontWeight: '400' }}>Nossos <span style={{ color: '#E23233', fontWeight: '600' }}>veículos</span></h1>

          <div className="select">
            <select
              className="custom-select model"
              style={{ width: '330px', paddingRight: '2.8em' }}
              value={selectedFilter}
              onChange={handleFilterChange}
            >
              <option value="" hidden>Ordenar por</option>
              <option value="lowest-price">Menor preço</option>
              <option value="highest-price">Maior preço</option>
              <option value="lowest-km">Menor quilometragem</option>
              <option value="highest-km">Maior quilometragem</option>
              <option value="lowest-year">Menor ano de fabricação</option>
              <option value="highest-year">Maior ano de fabricação</option>
            </select>
          </div>
        </div>

        <section className="cars">
          {filteredCars.map((car, index) => (
            <div className="ourcars-car" key={index}>
              <div className="ourcars-image" style={{ background: `url(https://joelveiculospv.com.br/admin/images/cars/${car.foto_principal})` }}></div>
              <div className="ourcars-data">
                <h1 className="ourcars-brand">{`${car.modelo}`}</h1>
                <h2 className="ourcars-model">{`${car.versao}`}</h2>
                <h1 className="ourcars-value">{`${car.preco}`}</h1>
              </div>

              <div className="ourcars-others">
                <h4 className="ourcars-km" style={{ fontWeight: '500' }}>{`${car.quilometragem}`} KM</h4>
                <h4 className="ourcars-years" style={{ fontWeight: '500' }}>{`${car.ano_fabricacao}`}/{`${car.ano_modelo}`}</h4>
              </div>

              <button className="see-more-details" id="see-more-details-page-cars" style={{ width: buttonWidth }} onClick={() => handleRedirectToProductPage(car.id)}>Ver mais detalhes</button>
            </div>
          ))}
        </section>
      </section>
    </div>
  )
}

export default Cars;